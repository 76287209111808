import React, { useEffect,useState } from 'react';
import loader from '../assets/loader.gif'; 
import {validate_contact} from '../Utils/validate';
import API from '../Utils/Api';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { gettoastwarning } from '../Utils/Common';
import { Modal,Button} from 'react-bootstrap';

import call from "../assets/call.png";

function ContactUs() {
const [errors,setErrors] = useState({});
const [inputs,setInputs] = useState({name:'',email:'',company_url:'',service_need:'',regions_intersted:'',telegram:'',whatsapp:'',additional_information:''});
const [show,setShow] = useState(false);
const [loading,setLoading] = useState(true);

useEffect(() => {  
   document.title = `CONTACT US | COZMUS`;
   document.getElementById('bodywrap').className='contact-main';
   setLoading(false);
},[]) 

/* Start Value set */
const handleInputChange = (event) => {
	
  event.persist();
  setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));       
}
/* End Value set */

/* Start Submit Sign */
const handleSubmit = (event) => {
	event.preventDefault();
	const validationErrors = validate_contact(inputs);
        const noErrors = Object.keys(validationErrors).length === 0;
		setErrors({});
        setErrors(validationErrors);
		if(noErrors){
			setLoading(true);
			let name=inputs.name;
			let email=inputs.email;
			let company_url=inputs.company_url;
			let service_need=inputs.service_need;
			let regions_intersted=inputs.regions_intersted;
			let telegram=inputs.telegram;
			let whatsapp=inputs.whatsapp;
			let additional_information=inputs.additional_information;
			let formData = new FormData(); 
			formData.append('name', name);
			formData.append('email', email);
			formData.append('company_url', company_url);
			formData.append('service_need', service_need);
			formData.append('regions_intersted', regions_intersted);
			formData.append('telegram', telegram);
			formData.append('whatsapp', whatsapp);
			formData.append('additional_information', additional_information);
			API.post('add_contact', formData)
				.then(response => {	
				if(response.data.status === 0){
					setLoading(false); 
					gettoastwarning(response.data.message,'');
				}else{
				   setLoading(false);
				   setShow(true);
				  //gettoastsuccess(response.data.message,'/contactus');
				}                  
			}).catch(error => {
				  setLoading(false); 
					gettoastwarning("Something went wrong. Please try again later.",'');
			});
		}
}

const handleClose = () => {
	setShow(false);
	window.location.href = "/contactus";
}

  return (
    <>
	<ToastContainer />	
      <section className="contactus-bg">
        <div className="container contactus-cards">
          <div className="row">
            <div className="col-md-10 offset-md-1 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
              <div className="row">
                <div className="col-md-6 ">
                  <div className="contactus-card">
                    <div className="contactus-heading">
                      <h1>Tell Us More About Your Business</h1>
                    </div>
                    <div className="contactus-para">
                      <p>
                        We'd love to learn more to provide a solution for your
                        business as accurately as possible. The more information you
                        tell us, the faster we can get to know you and respond in
                        the most useful way for you.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 ">
                  <div className="contactus-form">
                    <div className="mb-3">
                      <h3 className="contactus-form-heading">Your Information</h3>
                    </div>
                    <form method="post" onSubmit={handleSubmit} enctype="multipart/form-data">
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control contactus-form-input"
                          placeholder="Your Name"
						  name="name" onChange={handleInputChange} value={inputs.name} 
                        />
						{errors.name && (<p className="text-danger text-start">{errors.name}</p>)}
                      </div>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control contactus-form-input"
                          placeholder="Email"
						  name="email" onChange={handleInputChange} value={inputs.email}
                        />
						{errors.email && (<p className="text-danger text-start">{errors.email}</p>)}
                      </div>

                      <div className="mb-3">
					  <input
                          type="text"
                          className="form-control contactus-form-input"
                          placeholder="Company Website URL"
						  name="company_url" onChange={handleInputChange} value={inputs.company_url}
                        />
						{errors.company_url && (<p className="text-danger text-start">{errors.company_url}</p>)}
                      </div>
					  <div className="mb-3">
					  <input
                          type="text"
                          className="form-control contactus-form-input"
                          placeholder="What service do you need?"
						  name="service_need" onChange={handleInputChange} value={inputs.service_need}
                        />
						{errors.service_need && (<p className="text-danger text-start">{errors.service_need}</p>)}
                      </div>
					  <div className="mb-3">
					  <input
                          type="text"
                          className="form-control contactus-form-input"
                          placeholder="Which regions are you interested in?"
						  name="regions_intersted" onChange={handleInputChange} value={inputs.regions_intersted}
                        />
						{errors.regions_intersted && (<p className="text-danger text-start">{errors.regions_intersted}</p>)}
                      </div>
					  <div className="mb-3">
					  <input
                          type="text"
                          className="form-control contactus-form-input"
                          placeholder="Telegram"
						  name="telegram" onChange={handleInputChange} value={inputs.telegram}
                        />
						{errors.telegram && (<p className="text-danger text-start">{errors.telegram}</p>)}
                      </div>
					  <div className="mb-3">
					  <input
                          type="text"
                          className="form-control contactus-form-input"
                          placeholder="Whatsapp"
						  name="whatsapp" onChange={handleInputChange} value={inputs.whatsapp}
                        />
						{errors.whatsapp && (<p className="text-danger text-start">{errors.whatsapp}</p>)}
                      </div>
					  <div className="mb-3">
					  <textarea
						className="form-control contactus-form-input"
						placeholder="Any additional information you'd like to share?"
						name="additional_information"
						onChange={handleInputChange}
						value={inputs.additional_information}
					  />
					  {errors.additional_information && (<p className="text-danger text-start">{errors.additional_information}</p>)}
					</div>
                      
                      <div className="d-grid">
                        <button className="btn contactus-form-btn" type="submit">Send</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </section>
	  <Modal className="contpop" centered show={show} onHide={handleClose}>
		<Modal.Body>
		<img
			src={call}
			alt="icon"
			className="img-fluid"                    
		/>                    
		  <p>" Your inquiry is important to us, and we’ll respond promptly! "</p>
		  <Button variant="secondary" onClick={handleClose}>
			done
		  </Button>
		</Modal.Body>
	  </Modal>
	  {loading && (
	   <div className="loadingClass">
			<img src={loader} alt="loader"/>
		</div>
	   )}  
    </>
  );
}
export default ContactUs;
