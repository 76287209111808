import {  toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

// return the token from the session storage
export const getToken = () => {
  return localStorage.getItem("token") || null;
};

// remove the token and user from the session storage
export const removeUserSession = () => {
  localStorage.removeItem("token");
};

// set the token and user from the session storage
export const setUserSession = (token,profile_setup,type) => {
  localStorage.setItem("token", token);

};
 
export const getBaseurl = () => {
	//const BASE_URL = 'http://localhost:3000/';
	const BASE_URL = 'https://www.cozmus.com/';
  return BASE_URL
};

export const gettoastwarning = (message,url_link) => {
	toast.warning(message, {
				bodyClassName: 'custom-toast-body',
				toastClassName: 'custom-toast',
				position: "top-right",
				autoClose: 2000, 
		  onClose: () => {
			  if(url_link!=='')
			  {
				window.location.href = url_link;
			  }
		  },
		  style: {
			width: '320px', // Set the desired width
			marginTop: '5px', // Set the desired top margin
		  },
		  bodyStyle: {
			marginTop: '20px', // Set the desired margin for the text content
			marginBottom: '20px', // Set the desired margin for the text content
		  },
		});
}
export const gettoastsuccess = (message,url_link) => {
	toast.success(message, {
				bodyClassName: 'custom-toast-body',
				toastClassName: 'custom-toast',
				position: "top-right",
				autoClose: 2000,
				
		  onClose: () => {
			  if(url_link!=='')
			  {
					window.location.href = url_link;
			  }
		  },
		  style: {
			width: '320px', // Set the desired width
			marginTop: '5px', // Set the desired top margin
		  },
		  bodyStyle: {
			marginTop: '20px', // Set the desired margin for the text content
			marginBottom: '20px', // Set the desired margin for the text content
		  },
		});
}
