import React, { useEffect,useState, useRef } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import loader from '../assets/loader.gif'; 
import API from '../Utils/Api';
import { Link } from "react-router-dom";


import icn1 from "../assets/icn1.png";
import icn2 from "../assets/icn2.png";
import icn3 from "../assets/icn3.png";
import icn4 from "../assets/icn4.png";
import icn5 from "../assets/icn5.png";
import icn6 from "../assets/icn6.png";

import af from "../assets/af.png";
import as from "../assets/as.png";
import au from "../assets/au.png";
import eu from "../assets/eu.png";
import na from "../assets/na.png";
import sa from "../assets/sa.png";

import dot from "../assets/dot.png";






function Home() {
const [loading,setLoading] = useState(true);

const [inputsone,setInputsone] = useState({id:'',title:'',sub_title:'',description:''});

const [sectiontwo,setSectiontwo] = useState([]);

useEffect(() => {  
   document.title = `HOME | COZMUS`;
   document.getElementById('bodywrap').className='home-main';
   
   /* Start get home */
		API.get('home')
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
				setInputsone({
					id:response.data.data.section_one.id,
					title:response.data.data.section_one.title,
					sub_title:response.data.data.section_one.sub_title,
					description:response.data.data.section_one.description,
				});
				setSectiontwo(response.data.data.section_two);
			}   
		}).catch(error => {
			 setLoading(false);
		});  
	 /* End get home */
   
},[])   

  const sliderRef = useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false,              // Enable autoplay
    autoplaySpeed: 3000,         // Set the speed for autoplay (in milliseconds)
    centerMode: true,            // Enable center mode

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,

        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
    
  };

  const handleSlideClick = (index) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
  };

  const slides = [
    {
      id: 1,
      content: 'Africa',
      image: af,
    },
    {
      id: 2,
      content: 'Asia',
      image: as,
    },
    {
      id: 3,
      content: 'Australia',
      image: au,
    },
    {
      id: 4,
      content: 'Europe',
      image: eu,
    },
    {
      id: 5,
      content: 'North America',
      image: na,
    },
    {
      id: 6,
      content: 'South America',
      image: sa,
    },        
  ];


  return (
    <>
      {/* Main Container */}

      <section className="home-slide1">

      <div className="container">

        <div className="row">

          <div className="col-lg-6 col-md-6 text-white hs-left">
            <h1 className="home-heading">{inputsone.title}</h1>
            <h1 className="home-heading2">
              {inputsone.sub_title}
            </h1>
            <p className="home-heading-para">
              {inputsone.description}
            </p>
            <Link className="" to="/contactus">
            <button className="btn home-heding-btn px-4">Get Started</button>
            </Link>
          </div>

          <div className="col-lg-6 col-md-6 text-center hs-right">
            <div className="rotatecircle">
              <div className="item item1">
                <img
                  src={icn1}
                  className="img-fluid"
                  alt="..."
                />
              </div>
              <div className="item item2">
                <img
                  src={icn2}
                  className="img-fluid"
                  alt="..."
                />
              </div>
              <div className="item item3">
                <img
                  src={icn3}
                  className="img-fluid"
                  alt="..."
                />
              </div>
              <div className="item item4">
                <img
                  src={icn4}
                  className="img-fluid"
                  alt="..."
                />
              </div>
              <div className="item item5">
                <img
                  src={icn5}
                  className="img-fluid"
                  alt="..."
                />
              </div>
              <div className="item item6">
                <img
                  src={icn6}
                  className="img-fluid"
                  alt="..."
                />
              </div>
            </div>
          </div>

        </div>

      </div>
      </section>
      {/* Cards Container */}
      <section className="home-slide2">
        <div className="container">
            <div className="row">
			{sectiontwo && sectiontwo.map((val,index) => {
				return(
              <div className="col-lg-4 col-md-4 hm-card-box">
                <div className="card home-card text-white text-center py-5">
                  <img
                    src={val.image}
                    className="card-img-top m-auto"
                    alt="..."
                    style={{ width: "50px" }}
                  />
                  <div className="card-body">
                    <h5 className="card-title">{val.title}</h5>
                    <p className="card-text">
                      {val.sub_title}
                    </p>
                  </div>
                </div>
              </div>
              )
				})}   
            </div>
        </div>
      </section>  
      {/* Map Container Pending */}
      <section className="home-slide3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">

      <Slider ref={sliderRef} {...settings}>
        {slides.map((slide, index) => (
          <div key={slide.id} onClick={() => handleSlideClick(index)}>
              <div className="slbox">
                <div>
                  <img src={slide.image} alt="..." className="slimg" />              
                  <div className="slborder"><img src={dot} alt="..." className="sldot" /> </div> 
                  <h3>{slide.content}</h3>
                </div>
              </div>
          </div>
        ))}
      </Slider>
            </div>
          </div>
        </div>
      </section>
	  {loading && (
	   <div className="loadingClass">
			<img src={loader} alt="loader"/>
		</div>
	   )}  
    </>
  );
}
export default Home;